import { faChalkboard, faCircleInfo, faComment, faDollar, faHome, faQuestion, faWhiskeyGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';
import { Collapse, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import MiscUserActions from './misc-user-actions';

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            {/* expand indicates that the navbar will be fully expanded in medium and larger devices */}
            <Navbar dark expand="md" className="bg-success">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-1">
                            {/* mr-auto means add as much right margin as necessary */}
                            <NavbarBrand className="me-auto" href="/">
                                <Image src="/images/gearlogo.svg" height="48" width="48" alt="Wheel Strategy Tools" />
                            </NavbarBrand>
                        </div>
                        <div className="col-auto">
                            {/* NavbarToggler is a button that toggles in cooperation with the Collapse tag below. */}
                            <NavbarToggler onClick={toggleNav} height="48" width="48" />

                            {/* Collapse will collapse list in response to the isOpen parameter */}
                            <Collapse isOpen={isOpen} navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <Link href="/home" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faHome} />Home</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/articles" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faCircleInfo} />Articles</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/baskets" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faWhiskeyGlass} />Baskets</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/backtest" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faChalkboard} />BackTest</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/trade" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faDollar} />Trade</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/faq" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faQuestion} />FAQ</span></Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link href="/contactus" className="nav-link nav-text-color"><span><FontAwesomeIcon icon={faComment} />Contact Us</span></Link>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </div>
                        <div className="col-2">
                            <MiscUserActions />
                        </div>
                    </div>
                </div>
            </Navbar>
        </React.Fragment>
    )
}

export default NavBar
import { ClientMessage, SubscriptionStatus, SubscriptionStatusPayload, UserInfo, getSubscriptionStatusAddress } from '@wheelstrategytools/messages'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup } from 'reactstrap'
import { useLocalUser } from '../authentication/WSTAuthenticator'
import { BILLING_PORTAL_URL } from '../membership/upgrade-to-membership'
import { ClientIdContext } from '../utilities/client-id-provider'
import { StageContext, uberStageFromString } from '../utilities/stage-provider'
import { ClientWebSocketInterface } from '../websockets/clientwebsocket'

export interface UserAction {
  actionName: string,
  href: string
}

const MiscUserActions = () => {
  const clientId = React.useContext(ClientIdContext)
  const uberStage = uberStageFromString(React.useContext(StageContext))
  const localUser = useLocalUser()
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)

  const [GUEST_USER_ACTIONS, setGuestActions] = useState<UserAction[]>(
    [
      {
        actionName: 'Terms Of Service',
        href: '/termsofservice'
      },
      {
        actionName: 'Privacy Policy',
        href: '/privacypolicy'
      }
    ]
  )

  const [LOGGED_IN_USER_ACTIONS, setUserActions] = useState<UserAction[]>(
    [
      {
        actionName: 'Log Out',
        href: '/logout'
      },
      {
        actionName: 'Change Password',
        href: '/changepassword'
      }
    ]
  )

  const toggleActionDropdown = () => { setActionDropdownOpen(!actionDropdownOpen) }
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)

  const [subscriptionStatus, setSubscriptionStatus] = useState<string | undefined>(undefined)

  const [subscriptionUserAction, setSubscriptionUserAction] = useState<UserAction[]>([])

  useEffect(() => {
    if (localUser && localUser.data) {
      setUserInfo(localUser.data)
    }

    /*if (localUser.data && localUser.data.userLevel === Authorization.Member) {
      setSubscriptionUserAction([
        {
          actionName: 'Subscription',
          href: `${BILLING_PORTAL_URL}?prefilled_email=${localUser.data.userEmail}`
        }
      ])
    }else{
      setSubscriptionUserAction([])
    }*/
  }, [localUser.data])

  useEffect(() => {
    async function getSubscriptionStatus(): Promise<void> {
      const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)
      const receivedStatus = await clientWebSocketInterface.sendMsgAuthenticatedWithPromise<SubscriptionStatus>(
        new ClientMessage(getSubscriptionStatusAddress, [new SubscriptionStatusPayload({})], clientId)
      )

      if (receivedStatus) {
        setSubscriptionStatus(receivedStatus.subscriptionStatus)
      }
    }

    if (localUser.data) {
      getSubscriptionStatus()
    }
  }, [localUser.data])

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
  }

  function getActions(): UserAction[] {
    if (userInfo && subscriptionStatus) {
      return LOGGED_IN_USER_ACTIONS.concat([{
        actionName: `Subscription - currently ${subscriptionStatus}`,
        href: `${BILLING_PORTAL_URL}?prefilled_email=${userInfo.userEmail}`
      }]).concat(GUEST_USER_ACTIONS)
    } else if (userInfo) {
      return LOGGED_IN_USER_ACTIONS.concat(GUEST_USER_ACTIONS)
    } else {
      return GUEST_USER_ACTIONS
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div key={userInfo ? userInfo.userEmail : 'guestuser'}>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Dropdown size="sm" isOpen={actionDropdownOpen} toggle={toggleActionDropdown}>
                <DropdownToggle className="nav-text-color" color="bg-success" caret>
                  {userInfo ? userInfo.userEmail : 'Guest User'}
                </DropdownToggle>
                <DropdownMenu >
                  {
                    getActions().map(userAction =>
                      <DropdownItem tag={Link} href={userAction.href} className="itemHighlighted" key={userAction.actionName}>{userAction.actionName}</DropdownItem>
                    )
                  }
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Form>
          : <div></div>
        </div>
      </div>
    </div >
  )
}

export default MiscUserActions
import {
  AssetType,
  ClientMessage,
  CurrentStockQuotePayload,
  MarketStatus,
  MarketStatusPayload,
  ScenarioPayload, ScenarioReader,
  StockPosition,
  StockQuoteData,
  StockTransaction,
  UserInfo,
  UserInfoPayload,
  getLiveScenarioAddress,
  getMarketStatusAddress,
  getStockQuoteAddress,
  getUserAddress
} from '@wheelstrategytools/messages'
import axios, { AxiosResponse } from 'axios'
import React from 'react'
import { useQuery } from 'react-query'
import { WSTAuthenticator } from '../../components/authentication/authentication-types'
import { LiveScenarioSetup } from '../../components/live-scenario/live-scenario-types'
import { ClientWebSocketInterface } from '../../components/websockets/clientwebsocket'
import Head from 'next/head'
import { uberStageFromString, StageContext, UberStage } from '../../components/utilities/stage-provider'

export const useLiveScenario = (clientId: string, uberStage:UberStage) => useQuery(
  ['livescenario'],
  async (): Promise<ScenarioReader> => {
    const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)

    return new Promise((resolve, reject) => {

      clientWebSocketInterface.registerCallback(getLiveScenarioAddress(true),
        async (message: ClientMessage): Promise<void> => {

          const liveScenario = message.findPayloadFor(getLiveScenarioAddress(true)) as ScenarioPayload;

          if (liveScenario.data) {
            const s3Url = liveScenario.data.presignedLdoUrl
            const ldoData = await axios.get(s3Url).catch(error => {
              console.log('Caught error');
              console.log(error.response.data, { request: error.request })
            }) as AxiosResponse<any, any>;

            const result = new ScenarioReader(ldoData.data);
            resolve(result);
          }
        });

      clientWebSocketInterface.sendMsgAuthenticated(
        new ClientMessage(getLiveScenarioAddress(true), [], clientId)
      )
    })
  }
);

/*
  const openStockPositions = liveScenario.data.getOpenPositions().
                    filter(position => position.assetType === AssetType.Stock).
                    map(position => position as StockPosition)
                    const requestedTickerQuotes = openStockPositions.map(stockPosition => {
                        const stockTransaction = liveScenario.data.getTransaction(stockPosition.openingTransactionId) as StockTransaction
                        return {ticker: stockTransaction.ticker} as StockQuoteData
                    })

                const quotes = await ClientWebSocketInterface.sendMsgAuthenticatedWithPromise<StockQuoteData []>(
                    new ClientMessage(getStockQuoteAddress(true), [
                        new CurrentStockQuotePayload(getStockQuoteAddress(true),requestedTickerQuotes
                        )
                    ])
                )
*/

export const useCurrentStockQuotesForScenario = (clientId: string, uberStage:UberStage, liveScenario: ScenarioReader | undefined) => useQuery({
  queryKey: ['currentstockquotesforscenario'],
  queryFn: async (): Promise<StockQuoteData[]> => {
    const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)

    return new Promise((resolve, reject) => {

      clientWebSocketInterface.registerCallback(getStockQuoteAddress(true),
        async (message: ClientMessage): Promise<void> => {

          const stockQuotes = message.findPayloadFor(getStockQuoteAddress(true)) as CurrentStockQuotePayload;

          resolve(stockQuotes.data);
        });

      const openStockPositions = liveScenario!.getOpenPositions().
        filter(position => position.assetType === AssetType.Stock).
        map(position => position as StockPosition)
      const requestedTickerQuotes = openStockPositions.map(stockPosition => {
        const stockTransaction = liveScenario!.getTransaction(stockPosition.openingTransactionId) as StockTransaction
        return { ticker: stockTransaction.ticker } as StockQuoteData
      })

      clientWebSocketInterface.sendMsgAuthenticated(
        new ClientMessage(getStockQuoteAddress(true), [
          new CurrentStockQuotePayload(getStockQuoteAddress(true), requestedTickerQuotes
          )
        ],
          clientId)
      )
    })
  },
  enabled: !!liveScenario
});

export const useMarketStatus = (clientId: string, uberStage:UberStage) => useQuery(
  ['marketstatus'],
  async (): Promise<MarketStatus> => {
    const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)

    return new Promise((resolve, reject) => {
      clientWebSocketInterface.registerCallback(getMarketStatusAddress(true),
        async (message: ClientMessage): Promise<void> => {

          const marketStatus = message.findPayloadFor(getMarketStatusAddress(true)) as MarketStatusPayload;

          if (marketStatus.data) {
            resolve(marketStatus.data);
          }
        })

      clientWebSocketInterface.sendMsgAuthenticated(
        new ClientMessage(getMarketStatusAddress(true), [], clientId)
      )
    })
  }
);

export const useUser = (clientId: string, uberStage:UberStage) => useQuery(
  ['user'],
  async (): Promise<UserInfo> => {
    const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)

    return new Promise((resolve, reject) => {
      clientWebSocketInterface.registerCallback(getUserAddress,
        async (message: ClientMessage): Promise<void> => {

          const userInfo = message.findPayloadFor(getUserAddress) as UserInfoPayload;

          if (userInfo.data) {
            resolve(userInfo.data);
          }
        })

      clientWebSocketInterface.sendMsgAuthenticated(
        new ClientMessage(getUserAddress, [], clientId)
      )
    })
  }
)

const Trade = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Wheel Strategy Tools Trading Suggestions | wheelstrategytools.com | options wheel strategy trading suggestions</title>
        <meta
          name="description"
          content="Wheel Strategy Tools Basket Real Time Trading | wheelstrategytools.com | options wheel strategy trading"
          key="desc"
        />
        <meta
          property="og:description"
          content="Wheel Strategy Tools Basket Real Time Trading | wheelstrategytools.com | options wheel strategy trading"
        />
      </Head>
      <WSTAuthenticator ensureLogin={true} message='To trade you must register and become a member.'>
        <LiveScenarioSetup />
      </WSTAuthenticator>
    </React.Fragment>
  )
}



export default Trade
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import { devUserEnv, prodUserEnv } from '@wheelstrategytools/client';
import { ClientMessage, UserInfo, getUserAddress } from '@wheelstrategytools/messages';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import WST_UBERSTAGE from '../../config_for_uberstage.js';
import { ClientIdContext } from '../utilities/client-id-provider';
import { StageContext, UberStage, uberStageFromString } from '../utilities/stage-provider';
import { ClientWebSocketInterface } from '../websockets/clientwebsocket';

function createComponents(message: string) {
    return {
        Header() {
            const { tokens } = useTheme();
            return (
                <View textAlign="left" padding={tokens.space.large}>
                    <div className="container">
                        <div className="row">
                            <div className="col-2">
                                <Image
                                    alt="Wheel Strategy Tools Logo"
                                    src="/images/gearlogo.svg"
                                />
                            </div>
                            <div className="col-10">
                                <p><i>{message}</i></p>
                            </div>
                        </div>
                    </div>
                </View>
            )
        }
    }
}

// We're using react query to distribute local data.  This is for the case where 
// we need user information in the nav bar, but we can't get it until a page has
// rendered.
export const useLocalUser = () => useQuery({
    queryKey: ['localuser'],
    initialData: undefined as unknown as UserInfo,
    enabled: false,
    staleTime: Infinity,
    cacheTime: Infinity
})

/* const userEnv = (WST_UBERSTAGE as any) === 'prod'?prodUserEnv:devUserEnv

Auth.configure({
    Auth: {
        mandatorySignIn: true,
        signUpVerificationMethod: 'link',
        ...userEnv.userPool
    }
})*/

export const WSTAuthenticator = ({ ensureLogin, message, children }: { ensureLogin: boolean, message?: string, children: React.ReactNode }) => {
    const queryClient = useQueryClient()
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
    const clientId = React.useContext(ClientIdContext)
    const uberStage = uberStageFromString(React.useContext(StageContext))

    useEffect(() => {
        async function configureAuth() {
            const Auth = (await import('aws-amplify')).Auth
            const userEnv = uberStage === UberStage.PROD?prodUserEnv:devUserEnv
            Auth.configure({
                Auth: {
                    mandatorySignIn: true,
                    signUpVerificationMethod: 'link',
                    ...userEnv.userPool
                }
            })
        }

        configureAuth()
    }, [uberStage])

    useEffect(() => {
        async function getUserInfo(): Promise<void> {
            const clientWebSocketInterface = await ClientWebSocketInterface.getInterface(uberStage)
            const receivedUserInfo = await clientWebSocketInterface.sendMsgAuthenticatedWithPromise<UserInfo>(
                new ClientMessage(getUserAddress, [], clientId)
            )

            setUserInfo(receivedUserInfo)
        }

        if (loggedIn) {
            getUserInfo()
        }
    }, [loggedIn, clientId, queryClient])

    useEffect(() => {
        queryClient.setQueryData(['localuser'], userInfo)
    }, [userInfo])

    if (ensureLogin) {
        return (
            // Temporarily hide the sign up tab. hideSignUp={true} 
            <Authenticator hideSignUp={true} loginMechanisms={['email']} signUpAttributes={[]}
                components={createComponents(message ? message : '')}>
                {
                    ({ signOut, user }) => {
                        setLoggedIn(true)
                        return (
                            <React.Fragment>
                                {children}
                            </React.Fragment>
                        )
                    }
                }
            </Authenticator>
        )
    } else {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }
}

export default WSTAuthenticator
import { devUserEnv, prodUserEnv, RealWebSocketInterface } from '@wheelstrategytools/client';
import { UberStage } from '../utilities/stage-provider';

interface AuthParameters {
    signOut?: () => void,
    user?: String
}

// export let XClientWebSocketInterface = new RealWebSocketInterface('DevWebSocketInterface', Auth, webSocketProperties, true);

export class ClientWebSocketInterface {
    static webSocket: RealWebSocketInterface | undefined

    // getInterface will be called by multiple promises, but due to the way that promises are resolved
    // there won't ever be two 'threads' access it simultaneously, so we don't need to worry about
    // synchronization.
    static async getInterface(uberStage: UberStage): Promise<RealWebSocketInterface> {
        if (!ClientWebSocketInterface.webSocket) {
            const Auth = (await import('aws-amplify')).Auth
            const webSocketProperties = {
                mandatorySignIn: true,
                signUpVerificationMethod: 'link',
                webSocketEnvironment: uberStage === UberStage.PROD ? prodUserEnv : devUserEnv
            }

            ClientWebSocketInterface.webSocket = new RealWebSocketInterface('WebSocketInterface', Auth, webSocketProperties, true);

            return ClientWebSocketInterface.webSocket
        } else {
            return ClientWebSocketInterface.webSocket
        }
    }
}
import Link from 'next/link';
import { NavBar } from './navigation/NavBar';

export const TopLevelLayout = ({ children }: { children: any }) => {

    return (
        <>
            <NavBar />
            <main>{children}</main>
            <div className="d-flex justify-content-center">
                <p>&copy;2023 Wheel Strategy Tools, LLC</p>
            </div>
            <div className="d-flex justify-content-center">
                <p>By use of this site you consent to the <Link className="link" href="/termsofservice">terms of service </Link>
                    and the <Link className="link" href="/privacypolicy">privacy policy.</Link></p>
            </div>
        </>
    )
}

export default TopLevelLayout
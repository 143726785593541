import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

const CLIENT_ID_COOKIE_NAME = 'client-id'

export const ClientIdContext = React.createContext<string>('')

const ClientIdProvider = ({ children }: { children: React.ReactNode }) => {
  const [clientIdCookie, setClientIdCookie] = useCookies([CLIENT_ID_COOKIE_NAME])

  useEffect(() => {
    console.log(`clientIdCookie->${JSON.stringify(clientIdCookie)}`)
    if (CLIENT_ID_COOKIE_NAME in clientIdCookie) {
      // Do nothing
    } else {
      setClientIdCookie(CLIENT_ID_COOKIE_NAME, uuidv4(), { path: '/' })
    }
  }, [])

  return (
    <ClientIdContext.Provider value={clientIdCookie[CLIENT_ID_COOKIE_NAME]}>
      {children}
    </ClientIdContext.Provider >
  )
}

export default ClientIdProvider
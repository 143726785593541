import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-reflex/styles.css';
import '../styles/accountgroups.css';
import '../styles/article.css';
import '../styles/backgrounds.css';
import '../styles/baskets.css';
import '../styles/editable-table.css';
import '../styles/globals.css';
import '../styles/home.css';
import '../styles/logindialog.css';
import '../styles/modal-action-widget.css';
import '../styles/nav.css';
import '../styles/simulations.css';

import { Hub, HubCallback } from '@aws-amplify/core/lib/Hub';
import { Auth } from 'aws-amplify';
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TopLevelLayout } from '../components/toplevel-layout';
import ClientIdProvider from '../components/utilities/client-id-provider';
import { ClientWebSocketInterface } from '../components/websockets/clientwebsocket';
import { useRouter } from 'next/router';
import StageProvider, { StageContext, uberStageFromString } from '../components/utilities/stage-provider';
import React from 'react';

/*const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
});*/
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000
    }
  }
});

const useCognito = () => {
  const uberStage = uberStageFromString(React.useContext(StageContext))

  const authListener: HubCallback = ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
        ClientWebSocketInterface.getInterface(uberStage).then(clientWebInterface => clientWebInterface.setAuth(Auth))
        break;
      case 'signOut':
        ClientWebSocketInterface.getInterface(uberStage).then(clientWebInterface => clientWebInterface.close())

        // queryClient.clear()

        // Clear the browser cache on logout
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        break;
    }
  }

  useEffect(() => {
    Hub.listen('auth', authListener)
    return () => Hub.remove('auth', authListener)
  }, [])
}

export default function WSTApp({ Component, pageProps }: AppProps) {
  useCognito()
  const router = useRouter()

  return (
    <CookiesProvider>
      <StageProvider>
        <QueryClientProvider client={queryClient}>
          <ClientIdProvider>
            <TopLevelLayout>
              <Component {...pageProps} />
            </TopLevelLayout>
          </ClientIdProvider>
        </QueryClientProvider>
      </StageProvider>
    </CookiesProvider>
  );
}

